import React, { Fragment } from "react";

const About = () => {
  const data = [
    {
      head: "OUTDOOR ADVERTISING MARKETPLACE",
      paragh:
        " We are a global platform in the region to create a marketplace for all Outdoor Media spaces which literally spoils you for choice when you see the OOH inventory available.",
    },
    {
      head: "CONTENT MARKETING",
      paragh:
        "Our press release distribution package is by far the best in the region where we guarantee at least 10 Tier 1 platforms for the price and distribution to 50 other platforms.\n  We offer competitive rates for Gulf News, Khaleej Times, Al Khaleej, Gulf Today and other online platforms.",
    },
    {
      head: "LUXURY BRAND ACTIVATION",
      paragh:
        "With outdoor activation, you also need luxury platforms for content and here we have exclusivity with L’Officiel Arabia for their exclusive digital covers and content across digital and social media. ",
    },
    {
      head: "MEDIA BUYING",
      paragh:
        "With exclusive prices across newspaper, digital, outdoor, radio, metro, Dubai taxi and other mediums for advertising, there is no reason for you to deal with different partners for your marketing need.",
    },
    {
      head: "ARTIST MANAGEMENT",
      paragh:
        "From CSR campaigns to social events, we are here to cater to all your needs. From exhibitor stall production, caricature art, musicians, singers, influencers and NFT artists are available on our platform for brand engagement. We are connected to the best influencer network in UAE & KSA",
    },
    {
      head: "EVENTS & EXHIBITIONS",
      paragh:
        " Our events team has posted a calendar of industry focused events, seminars and shows across the year. Apart from this, we have customized events to cater to real estate launch, fashion shows, art collections and other niche events.",
    },
    {
      head: "FASTER COMMUNICATION",
      paragh:
        " WhatsApp integration allows faster communication and response time for time sensitive activations and brand requirements. Our team is made of industry experts who have spent years in UAE corporate marketing and very well understand the importance of meeting deadlines and being proactive with solutions.        ",
    },
  ];
  return (
    <Fragment>
      <div className="PolicyTextSec DBlock">
        <div className="container">
          <div className="PolicyArea DBlock">
            <div className="Title DBlock h-auto AboutTitle">
              <h2>The World’s first marketplace for Artists</h2>
            </div>
            <div className="Description DBlock">
              <div className="TextDiv DBlock h-auto">
                {/* <h4>1. How does spacenartists.com stand out and what is the USP of the portal?</h4> */}
                <p>
                  Spacenartists.com as the name suggests is your marketplace for
                  advertising and collaborating with artists for your brand
                  marketing requirement. The first ever platform to showcase
                  Outdoor advertising inventory across UAE. Connect with
                  industry experts to plan your marketing campaigns and
                  customize campaigns as per your target audience.
                </p>

                {data?.map((item, index) => (
                  <div key={index}>
                    <h4>{item?.head}</h4>
                    <p>{item?.paragh}</p>
                  </div>
                ))}

                <p>
                  Book a meeting with our team today and allow us to create a
                  solution based on your target audience and the right reach.
                </p>
                <p>Johnson Mendonca</p>
                <p>Head of Partnerships</p>
                <p>054 3878786</p>
                <p>johnson@spacenartists.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
