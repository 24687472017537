import React, { Fragment } from "react";

const Cpryt = () => {
  return (
    <Fragment>
      <div className="CprytDiv DFlex justify-content-center text-center">
        <img src="/assets/svg/CprytLogo.svg" alt="" />
        <p>
          spacenartists, Copyright © 2024, All Rights Reserved. Powered by{" "}
          <a  href="https://www.clicktap.ae/" style={{ cursor: "pointer" }}>Clicktap Digital</a>
        </p>
      </div>
    </Fragment>
  );
};

export default Cpryt;
